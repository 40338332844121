import React, { useState, useEffect } from 'react';

import fetchJsonp from 'fetch-jsonp';

import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/Layout';

import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';

require('es6-promise').polyfill();

const JobAlertsPage = (props) => {
  const { t } = useI18nContext();

  const siteConfig = props.pageContext.config || {};

  const apiKey = props.smartfeedID || siteConfig.apiKey || props.smartfeedID;
  const gdprKey =
    props.smartfeedID ||
    siteConfig.gdprKey ||
    props.apiKey ||
    props.smartfeedID;
  const groupOrIdParam = siteConfig.group ? 'group' : 'id';
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);
  const [unsubID, setUnsubID] = useState(null);
  const [unsubStatus, setUnsubStatus] = useState('initial');

  useEffect(() => {
    if (apiKey) {
      let searchParams = new URLSearchParams(props.location.search);
      let entries = searchParams.entries();

      for (let pair of entries) {
        if (pair[0] === 'UnsubscribeID') {
          setUnsubID(pair[1]);
        }
      }

      let companyDetailsEndpoint = 'GetCompanyDetails';

      if (groupOrIdParam === 'group') {
        companyDetailsEndpoint = 'GetGroupDetails';
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            '/CareerPage/' +
            companyDetailsEndpoint +
            '?id=' +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                '/CareerPage/' +
                'GetApplicantGetwayURL' +
                '?companyId' +
                '=' +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, 'error');
              });
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, 'error');
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError('No API key was provided.');
    }
  }, [
    apiKey,
    groupOrIdParam,
    gdprKey,
    props.location.search,
    siteConfig.sfAPIEndpoint,
  ]);

  function unsubFromJobAlerts(unsubID) {
    var dataObjOptIn = {
      JobAlertId: encodeURIComponent(unsubID),
    };

    const dataObjQueryString = Object.keys(dataObjOptIn)
      .map((key) => key + '=' + dataObjOptIn[key])
      .join('&');

    fetchJsonp(
      siteConfig.sfAPIEndpoint +
        '/CareerPage/' +
        'UnsubscribeFromJobAlert?' +
        dataObjQueryString,
      {
        timeout: 15000,
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonList) {
        if (jsonList !== 'Error') {
          setVacanciesRouteLoading(false);
          setUnsubStatus('Success');
        } else {
          setVacanciesRouteLoading(false);
          setUnsubStatus('Error');
        }
      })
      .catch(function (ex) {
        setUnsubStatus('Error');
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      });
  }

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={apiKey}
      jobAlertsPage={true}
      groupOrIdParam={groupOrIdParam}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      title="Unsubscribe from Job Alerts"
    >
      {!smartfeedCompanyData ? (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  <p>{t('job-alerts.messages.please-wait-config')}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : smartfeedCompanyData &&
        !vacanciesRouteLoading &&
        !vacanciesRouteError ? (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  {unsubID ? (
                    <>
                      <h1>{t('job-alerts.unsubscribe.title')}</h1>
                      <p>{t('job-alerts.unsubscribe.subtitle')}</p>
                    </>
                  ) : (
                    <h1>{t('job-alerts.unsubscribe.id-required')}</h1>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="jobalerts-landing">
            <div className="container">
              <div className="row clearfix">
                <div className="col-md-12 column">
                  <div className="smartfeed-spacing">
                    {smartfeedCompanyData.JobAlertCompanySettings && unsubID ? (
                      <div className="col-12 col-lg-8 job-alerts-panel opt-in-panel">
                        {unsubStatus === 'initial' ? (
                          <button
                            onClick={() => unsubFromJobAlerts(unsubID)}
                            className="nav-action-sm"
                          >
                            {t('job-alerts.unsubscribe.unsubscribe-job-alerts')}
                          </button>
                        ) : unsubStatus === 'Success' ? (
                          <strong>{t('job-alerts.unsubscribe.success')}</strong>
                        ) : unsubStatus === 'Error' ? (
                          <strong>
                            {t('job-alerts.unsubscribe.unknown-error')}
                          </strong>
                        ) : (
                          <strong>
                            {t('job-alerts.unsubscribe.unknown-status')}
                          </strong>
                        )}
                      </div>
                    ) : (
                      <p>{t('job-alerts.unsubscribe.could-not-load')}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  <p>{t('job-alerts.unsubscribe.please-wait')}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default JobAlertsPage;
